import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import './App.css';

const Navbar = ({ isAuthenticated, setIsAuthenticated, username }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
//
  const handleSignOut = () => {
    localStorage.removeItem('token'); // Clear the token from local storage
    localStorage.removeItem('username'); // Clear the username from local storage
    setIsAuthenticated(false);
    window.location.reload();
  };

  return (
    <nav className="bg-cyan-700 p-4">
      <div className="container mx-auto flex items-center justify-between">
        <div className="flex items-center text-white text-4xl title">
          <a href='https://marghob.net'>منصة مرغوب</a>
        </div>

        <div className="hidden md:flex space-x-4">
          <RouterLink to="/" className="text-white ml-6">الرئيسية</RouterLink>
          <ScrollLink to="about-us" smooth={true} duration={500} className="text-white cursor-pointer">عن المنصة</ScrollLink>
          <ScrollLink to="contact" smooth={true} duration={500} className="text-white cursor-pointer">تواصل معنا</ScrollLink>
          
          {!isAuthenticated && <RouterLink to="/signin" className="text-white">تسجيل الدخول</RouterLink>}
          {!isAuthenticated && <RouterLink to="/signup" className="text-white">إنشاء حساب</RouterLink>}
          {isAuthenticated && (
            <>
              <span className="text-white">مرحبًا, {username}</span>
              <button onClick={handleSignOut} className="text-white">تسجيل الخروج</button>
            </>
          )}
        </div>

        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMobileMenu}
            className="text-white focus:outline-none"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
      </div>

      {mobileMenuOpen && (
        <div className="md:hidden bg-cyan-700 p-4">
          <RouterLink to="/" className="block text-white py-2">الرئيسية</RouterLink>
          <ScrollLink to="about-us" smooth={true} duration={500} className="block text-white py-2 cursor-pointer">عن المنصة</ScrollLink>
          <ScrollLink to="contact" smooth={true} duration={500} className="block text-white py-2 cursor-pointer">تواصل معنا</ScrollLink>
          {!isAuthenticated && <RouterLink to="/signin" className="block text-white py-2">تسجيل الدخول</RouterLink>}
          {!isAuthenticated && <RouterLink to="/signup" className="block text-white py-2">إنشاء حساب</RouterLink>}
          {isAuthenticated && (
            <>
              <span className="block text-white py-2">مرحبًا, {username}</span>
              <button onClick={handleSignOut} className="block text-white py-2">تسجيل الخروج</button>
            </>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
