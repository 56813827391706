import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FreeLancers from './FreeLancers';
import JobCard from './JobCard';
import loading from './loading.svg'


const SearchComponent = ({ onSignIn, onSignUp }) => {
  const [job, setJob] = useState('');
  const [city, setCity] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.marghob.net/inputs');
        if (response.status === 200) {
          setSearchResults(response.data);
          setError('');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('An error occurred while fetching data.');
        setSearchResults([]);
      }
    };

    fetchData();
  }, []);

  const handleSearch = async (searchJob = job, searchCity = city) => {
    setIsLoading(true);
    try {
      const params = {};
      if (searchJob) params.job = searchJob;
      if (searchCity) params.city = searchCity;

      const response = await axios.get('https://api.marghob.net/search', { params });
      
      if (response.status === 200) {
        setSearchResults(response.data);
        setError('');
      } else {
        setError('No results found.');
        setSearchResults([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('An error occurred while fetching data.');
      setSearchResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleJobCardClick = (jobTitle) => {
    setJob(jobTitle);
    handleSearch(jobTitle, city);
  };

  return (
    <div className="CyanBack p-4 space-y-4 ">
      <div className="flex justify-center mb-4">
        <div className="grid w-2/3 grid-cols-1 md:grid-cols-3 gap-4">
          <JobCard jobTitle="مصور" onCardClick={handleJobCardClick} />
          <JobCard jobTitle="رسام جداريات" onCardClick={handleJobCardClick} />
          <JobCard jobTitle="كهربائي" onCardClick={handleJobCardClick} />
          <JobCard jobTitle="سباك" onCardClick={handleJobCardClick} />
          <JobCard jobTitle="ميكانيكي سيارات" onCardClick={handleJobCardClick} />
          <JobCard jobTitle="منسق حفلات" onCardClick={handleJobCardClick} />
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:items-center md:justify-center mb-4">
        <select
          value={job}
          onChange={(e) => setJob(e.target.value)}
          className="p-1 border rounded placeholder:text-slate-500 border-slate-700 mb-2 md:mb-0 md:mr-4 flex-grow md:flex-grow-0 md:w-1/4"
        >
          <option value="">اختر الخدمة</option>
          <option value="مصور">مصور</option>
          <option value="رسام جداريات">رسام جداريات</option>
          <option value="كهربائي">كهربائي</option>
          <option value="سباك">سباك</option>
          <option value="منسق حفلات">منسق حفلات</option>
          <option value="ميكانيكي سيارات">ميكانيكي سيارات</option>
        </select>
        <select
  value={city}
  onChange={(e) => setCity(e.target.value)}
  className="p-1 border rounded placeholder:text-slate-500 border-slate-700 mb-2 md:mb-0 md:mr-4 flex-grow md:flex-grow-0 md:w-1/4"
>
  <option value="">اختر المدينة</option>
  <option value="الرياض">الرياض</option>
  <option value="جدة">جدة</option>
  <option value="مكة">مكة</option>
  <option value="المدينة المنورة">المدينة المنورة</option>
  <option value="الدمام">الدمام</option>
  <option value="الخبر">الخبر</option>
  <option value="تبوك">تبوك</option>
  <option value="بريدة">بريدة</option>
  <option value="حفر الباطن">حفر الباطن</option>
  <option value="خميس مشيط">خميس مشيط</option>
  <option value="الطائف">الطائف</option>
  <option value="جيزان">جيزان</option>
  <option value="نجران">نجران</option>
  <option value="الجبيل">الجبيل</option>
  <option value="ينبع">ينبع</option>
  <option value="أبها">أبها</option>
  <option value="عرعر">عرعر</option>
</select>

    
        {isLoading ? (
            <div className="mb-4 mr-4">
            {/* Placeholder for Icon */}
            <img src={loading} alt="Icon" className="w-16 h-16" />
          </div>
        ) : (
          <button
            onClick={() => handleSearch(job, city)}
            disabled={isLoading}
            className="p-2 bg-gray-400 text-white rounded hover:bg-gray-500 w-full md:w-auto px-10 md:ml-4 lg:mr-10 md:mr-6"
          >
            بحث
          </button>
        )}
      </div>

      {error && <p className="text-red-500">{error}</p>}
      {searchResults.length > 0 ? (
        <FreeLancers FreeLancer={searchResults} />
      ) : (
        <p className="text-gray-600">لم نحصل على النتيجة المطلوبة</p>
      )}
    </div>
  );
};

export default SearchComponent;
