import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

// Create a context for authentication
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);

// AuthProvider component to wrap around the application and provide the context
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [userPosts, setUserPosts] = useState([]);

  // Check if the user is authenticated on mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    if (token && storedUsername) {
      setIsAuthenticated(true);
      setUsername(storedUsername);
      fetchUserPosts(token);
    }
  }, []);

  const fetchUserPosts = async (token) => {
    try {
      const response = await axios.get('https://api.marghob.net/myinputs', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUserPosts(response.data);
    } catch (error) {
      console.error('Error fetching posts:', error);
      setUserPosts([]); // Set userPosts to an empty array if there's an error or no posts
    }
  };

  const refreshPosts = () => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserPosts(token);
    }
  };

  const login = (token, username) => {
    localStorage.setItem('token', token);
    localStorage.setItem('username', username);
    setIsAuthenticated(true);
    setUsername(username);
    fetchUserPosts(token);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    setIsAuthenticated(false);
    setUsername('');
    setUserPosts([]);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, username, userPosts, refreshPosts }}>
      {children}
    </AuthContext.Provider>
  );
};
