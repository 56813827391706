import React from 'react';
import locate from './locate.svg' 
import tools from './Tools.svg' 
import online from './online.svg'
import teamwork from './teamwork.svg'

function AboutUs() {
  return (
    <div className="p-6 min-h-screen flex flex-col items-center ">
      <div className="max-w-6xl w-full space-y-12">
        <h2 className="text-4xl font-bold text-cyan-700 text-center mb-12">كيف يمكن لمرغوب مساعدتك</h2>

        <div>
          <h3 className="text-3xl font-semibold text-cyan-700 text-center mb-6">عملاء مرغوب</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex flex-col items-center text-center p-6 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105">
              <div className="mb-4">
                {/* Placeholder for Icon */}
                <img src={locate} alt="Icon" className="w-16 h-16" />
              </div>
              <h3 className="text-xl font-semibold text-cyan-950 mb-2">أنجز أعمالك بسرعة وسهولة</h3>
              <p className="text-lg text-cyan-950">مع مرغوب تستطيع انجاز اعمالك من خلال محترفين بالقرب منك</p>
            </div>

            <div className="flex flex-col items-center text-center p-6 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105">
              <div className="mb-4">
                {/* Placeholder for Icon */}
                <img src={tools} alt="Icon" className="w-16 h-16" />
              </div>
              <h3 className="text-xl font-semibold text-cyan-950 mb-2">كل احتياجتك متوفرة</h3>
              <p className="text-lg text-cyan-950">في مرغوب نتميز بوجود مهن وحرف تلبي جميع احتياجاتك</p>
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-3xl font-semibold text-cyan-700 text-center mb-6">محترفين مرغوب</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex flex-col items-center text-center p-6 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105">
              <div className="mb-4">
                {/* Placeholder for Icon */}
                <img src={online} alt="Icon" className="w-16 h-16" />
              </div>
              <h3 className="text-xl font-semibold text-cyan-950 mb-2">مساحة تسويقية</h3>
              <p className="text-lg text-cyan-950">في مرغوب نوفر لك مساحة لنشر اعمالك</p>
            </div>

            <div className="flex flex-col items-center text-center p-6 bg-white shadow-lg rounded-lg transition-transform transform hover:scale-105">
              <div className="mb-4">
                {/* Placeholder for Icon */}
                <img src={teamwork} alt="Icon" className="w-16 h-16" />
              </div>
              <h3 className="text-xl font-semibold text-cyan-950 mb-2">المحترف والعميل بمكان واحد</h3>
              <p className="text-lg text-cyan-950">لتقديم افضل الخدمات نسعى في مرغوب على ربط المحترف مع العميل بنفس المدينة</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
