import React, { useState } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

const PostItem = ({ post, onPostUpdated }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: post.name,
    job: post.job,
    city: post.city,
    salary: post.salary,
    mobileNumber: post.mobileNumber,
    description: post.description,
  });
  const [errors, setErrors] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `https://api.marghob.net/inputs/${post._id}`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      onPostUpdated(); // Call onPostUpdated after a successful update
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating post:', error);
      if (error.response) {
        if (error.response.data && error.response.data.message) {
          setErrors(error.response.data.message);
        } else {
          setErrors('An error occurred, but the server did not provide any details.');
        }
      } else if (error.request) {
        setErrors('No response received from server. Please check your network connection.');
      } else {
        setErrors('An unexpected error occurred. Please try again.');
      }
    }
  };

  const handleCloseModal = () => {
    setIsEditing(false);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="">
        <div
          className="border bg-cyan-700 flex flex-col items-center text-center p-6  shadow-lg rounded-lg transition-transform transform hover:scale-105 cursor-pointer"
          onClick={() => setIsEditing(true)}
        >
          <h3 className="text-lg font-semibold mb-2 text-white">{post.name}</h3>
          <p className="text-sm mb-1 text-white">
            <strong>العمل:</strong> {post.job}
          </p>
          <p className="text-sm mb-1 text-white">
            <strong>المدينة:</strong> {post.city}
          </p>
          <p className="text-sm mb-1 text-white">
            <strong>السعر بالساعة:</strong> {post.salary}
          </p>
          <p className="text-sm mb-1 text-white">
            <strong>رقم الجوال:</strong> {post.mobileNumber}
          </p>
          <p className="text-sm mb-2 text-white">
            <strong>الوصف:</strong> {post.description}
          </p>
          <div className="flex flex-wrap mb-4">
  {post.photos.slice(0, 2).map((photo, index) => (
    <img
      key={index}
      src={photo}
      alt="Post"
      className="w-24 h-24 object-cover rounded mr-2 mb-2"
    />
  ))}
</div>

        </div>
      </div>
      <AnimatePresence>
        {isEditing && (
          <>
            <motion.div
              className="fixed inset-0 bg-gray-800 bg-opacity-50 z-10"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={handleCloseModal}
            ></motion.div>

            <motion.div
              className="fixed inset-0 z-20 flex items-center justify-center p-4 sm:p-6 overflow-y-auto"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
            >
              <form
                onSubmit={handleSubmit}
                className="bg-white p-4 sm:p-6 w-full max-w-lg max-h-full overflow-y-auto rounded shadow-md"
              >
                <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-cyan-700">
                  تحديث بيانات المحترف
                </h2>
                {errors && <p className="text-red-500 mb-4">{errors}</p>}
                <h3 className="block w-full p-2 border rounded mb-2">{post.name}</h3>
                <input
                  type="text"
                  name="job"
                  value={formData.job}
                  onChange={handleChange}
                  placeholder="العمل"
                  className="block w-full p-2 border rounded mb-2"
                  required
                />
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  placeholder="المدينة"
                  className="block w-full p-2 border rounded mb-2"
                  required
                />
                <input
                  type="text"
                  name="salary"
                  value={formData.salary}
                  onChange={handleChange}
                  placeholder="السعر بالساعة"
                  className="block w-full p-2 border rounded mb-2"
                  required
                />
               <h3 className="block w-full p-2 border rounded mb-2">{post.mobileNumber}</h3>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  placeholder="الوصف"
                  className="block w-full p-2 border rounded mb-2"
                />
                 <div className="flex flex-wrap mb-4">
            {post.photos.map((photo, index) => (
              <img
                key={index}
                src={photo}
                alt="Post"
                className="w-24 h-24 object-cover rounded mr-2 mb-2"
              />
            ))}
          </div>
                <div className="flex justify-end mt-4">
                  <button
                    type="submit"
                    className="p-2 bg-cyan-700 text-white rounded hover:bg-cyan-900 transition-colors duration-200 mx-4"
                  >
                    تحديث
                  </button>
                  <button
                    type="button"
                    onClick={handleCloseModal}
                    className="p-2 bg-gray-400 text-white rounded hover:bg-gray-700 transition-colors duration-200 ml-2"
                  >
                    إلغاء
                  </button>
                </div>
              </form>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PostItem;
