import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = ({ title, description, keywords, structuredData, url }) => (
  <Helmet>
    <html lang="ar" dir="rtl" />
    <title>{title}</title>
    <meta charSet="utf-8" />
    <link rel="icon" href="/static/media/Logo.svg" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    
    {/* Open Graph tags */}
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:locale" content="ar_AR" />
    
    {/* Twitter Card tags */}
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:site" content="@Marghobapp" />

    {structuredData && (
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    )}
  </Helmet>
);

export default SEO;
