import React from 'react'
import { Link } from 'react-router-dom';


function TakeToSign() {
  return (
    <div>


<div className='mt-10'> 
      <h2 className="text-3xl font-semibold mb-4 text-cyan-700">سجل معنا كمحترف</h2>
      <h2 className="text-2xl font-semibold mb-4 text-cyan-700 mt-10 ">هل أنت مقدم خدمة وترغب في الوصول إلى جمهور أوسع؟</h2>
      <p className='font-bold m-10 text-lg'> ابدأ رحلتك كمحترف مستقل من خلال منصة مرغوب التي تساعدك في الحصول على عملاء موثوقين في مدينتك بشكل مجاني بالكامل</p>
      {/* Button to open the modal */}
      <Link to="/signup" className="text-white">
      <button
        
        className="p-2 bg-gray-400 text-white rounded hover:bg-cyan-900 px-10 text-lg mb-10"
      >
        سجل معنا الآن
      </button>
      
      </Link>
      </div>



    </div>
  )
}

export default TakeToSign