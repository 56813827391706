import React from 'react';
import InputForm from './InputForm';
import SearchComponent from './SearchComponent';
import PostList from './PostList';
import { useAuth } from './AuthContext';
import Intro from './Intro';
import AboutUs from './AboutUs';
import HowItWork from './HowItWork';
import TakeToSign from './TakeToSign';
import { Element } from 'react-scroll';

const Home = () => {
  const { isAuthenticated, userPosts } = useAuth();

 //
  
  

  return (
    <div>
      <Element name="intro">
        <Intro />
      </Element>

      {isAuthenticated ? null : <TakeToSign />}
      {isAuthenticated && <InputForm />}
      {isAuthenticated && userPosts.length > 0 && <PostList />}

     <HowItWork />

      <Element name="search">
        <SearchComponent />
      </Element>

 

      <Element name="about-us">
        <AboutUs />
      </Element>
    </div>
  );
};

export default Home;


