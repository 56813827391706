import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import './App.css';

const SignUp = () => {
  const [username, setUsernameInput] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPasswordConditions, setShowPasswordConditions] = useState(false);
  const [passwordConditions, setPasswordConditions] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false,
  });

  const { login } = useAuth();

  const validatePassword = (password) => {
    const conditions = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /[0-9]/.test(password),
      specialChar: /[!@#$%^&*]/.test(password),
    };
    setPasswordConditions(conditions);
    return Object.values(conditions).every(Boolean);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (!validatePassword(newPassword)) {
      setShowPasswordConditions(true);
    } else {
      setShowPasswordConditions(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    if (password !== repeatPassword) {
      setError('كلمتا المرور غير متطابقتين.');
      setLoading(false);
      return;
    }
    if (!validatePassword(password)) {
      setError('يرجى التأكد من أن كلمة المرور تستوفي جميع الشروط.');
      setLoading(false);
      setShowPasswordConditions(true);
      return;
    }
    try {
      const response = await axios.post('https://api.marghob.net/signup', { username, email, password });
      const token = response.data.token;
      localStorage.setItem('token', token); // Store token
      localStorage.setItem('username', username); // Store username
      setLoading(false);
      setUsernameInput('');
      setEmail('');
      setPassword('');
      setRepeatPassword('');
      login(token, username); // Authenticate user
      window.location.href = '/'; // Redirect to home page
    } catch (error) {
      console.error('Sign up error:', error);
      if (error.response) {
        if (error.response.status === 400) {
          setError('تفاصيل التسجيل غير صحيحة');
        } else {
          setError(`خطأ: ${error.response.data.message || 'حدث خطأ ما'}`);
        }
      } else if (error.request) {
        console.error('Error request:', error.request);
        setError('لا يوجد استجابة من الخادم. يرجى المحاولة لاحقاً.');
      } else {
        console.error('Error message:', error.message);
        setError('خطأ: ' + error.message);
      }
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-intro">
      <div className="w-full max-w-md p-8 bg-sky-100 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center">إنشاء حساب</h2>
        <form onSubmit={handleSubmit} className="mt-4">
          <div className="mb-4">
            <label className="block text-gray-700">اسم المستخدم</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsernameInput(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded-lg"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">البريد الإلكتروني</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded-lg"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">كلمة المرور</label>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              required
              className="w-full px-3 py-2 border rounded-lg"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">تأكيد كلمة المرور</label>
            <input
              type="password"
              value={repeatPassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded-lg"
            />
          </div>
          {showPasswordConditions && (
            <ul className="text-sm text-gray-700 mb-4">
              <li className={passwordConditions.length ? 'text-green-500' : 'text-red-500'}>يجب أن تكون كلمة المرور مكونة من 8 أحرف على الأقل</li>
              <li className={passwordConditions.uppercase ? 'text-green-500' : 'text-red-500'}>يجب أن تحتوي على حرف كبير واحد على الأقل</li>
              <li className={passwordConditions.lowercase ? 'text-green-500' : 'text-red-500'}>يجب أن تحتوي على حرف صغير واحد على الأقل</li>
              <li className={passwordConditions.digit ? 'text-green-500' : 'text-red-500'}>يجب أن تحتوي على رقم واحد على الأقل</li>
              <li className={passwordConditions.specialChar ? 'text-green-500' : 'text-red-500'}>يجب أن تحتوي على رمز خاص واحد على الأقل (مثل !@#$%^&*)</li>
            </ul>
          )}
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <button type="submit" className="w-full py-2 mt-4 text-white bg-cyan-700 rounded-lg hover:bg-cyan-600" disabled={loading}>
            {loading ? 'جاري إنشاء الحساب...' : 'إنشاء حساب'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
