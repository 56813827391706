import React, { useState } from 'react';
import axios from 'axios';
import { motion } from "framer-motion";

function InputForm() {
  const [inputData, setInputData] = useState({
    name: '',
    job: '',
    city: '',
    salary: '',
    mobileNumber: '',
    description: '',
  });

  const [photos, setPhotos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputData({ ...inputData, [name]: value });
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const validTypes = ['image/jpeg', 'image/png'];
    const invalidFiles = files.filter(file => !validTypes.includes(file.type));

    if (invalidFiles.length > 0) {
      setError('Only JPEG and PNG images are allowed.');
      return;
    }

    setPhotos(files);
    setError('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isNaN(inputData.salary)) {
      console.error('Salary must be a number');
      setError('Salary must be a number');
      return;
    }

    const formData = new FormData();
    formData.append('name', inputData.name);
    formData.append('job', inputData.job);
    formData.append('city', inputData.city);
    formData.append('salary', inputData.salary);
    formData.append('mobileNumber', inputData.mobileNumber);
    formData.append('description', inputData.description);

    for (let i = 0; i < photos.length; i++) {
      formData.append('photos', photos[i]);
    }

    try {
      const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
      const response = await axios.post('https://api.marghob.net/inputs', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Input created:', response.data);
      setInputData({
        name: '',
        job: '',
        city: '',
        salary: '',
        mobileNumber: '',
        description: '',
      });
      setPhotos([]);
      handleCloseModal();
    } catch (error) {
      console.error('Error creating input:', error);
      setError('Error creating input');
      // Display an error message to the user
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className='mt-10'> 
      <h2 className="text-3xl font-semibold mb-4 text-cyan-700">ابداعك يستمر معنا</h2>
      <p className='font-bold m-10 text-lg'>المنشور الواضح والمحدد يساعدك في جذب عملاء أكثر</p>
      {/* Button to open the modal */}
      <button
        onClick={handleOpenModal}
        className="p-2 bg-gray-400 text-white rounded hover:bg-cyan-900 px-10 text-lg mb-10"
      >
        أضف منشورك
      </button>

      {/* Modal */}
      {showModal && (
        <div>
          {/* Grey overlay for the background */}
          <div
            className="fixed inset-0 bg-gray-800 bg-opacity-50 z-10"
            onClick={handleCloseModal}
          ></div>

          {/* Modal content */}
          <div className="fixed inset-0 z-20 overflow-y-auto flex items-center justify-center">
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.3 }}
              className="flex items-center justify-center min-h-screen"
            >
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.3 }}
                className="bg-white p-4 w-96 rounded shadow-md"
              >
                <h2 className="text-2xl font-semibold mb-4 text-cyan-700">بيانات المحترف</h2>

                {error && (
                  <div className="mb-4 text-red-500">
                    {error}
                  </div>
                )}

                <form onSubmit={handleSubmit}>
                  <label className="block mb-2">
                    الإسم :
                    <input
                      type="text"
                      name="name"
                      value={inputData.name}
                      onChange={handleChange}
                      placeholder="الإسم"
                      className="block w-full p-2 border rounded mt-1"
                    />
                  </label>

                  
                  <label className="block mb-2">
  العمل: 
  <select
    name='job'
    onChange={handleChange}
    className="block w-full p-2 border rounded mt-1 text-center"
  >
    <option value="">اختر الخدمة</option>
    <option value="مصور">مصور</option>
    <option value="رسام جداريات">رسام جداريات</option>
    <option value="كهربائي">كهربائي</option>
    <option value="سباك">سباك</option>
    <option value="منسق حفلات">منسق حفلات</option>
    <option value="ميكانيكي سيارات">ميكانيكي سيارات</option>
  </select>
</label>


                  <label className="block mb-2">
                    المدينة :
                    <select
          name="city"
          onChange={handleChange}
          className="block w-full p-2 border rounded mt-1 text-center"
        >
          <option value="">اختر المدينة</option>
          <option value="الرياض">الرياض</option>
          <option value="جدة">جدة</option>
          <option value="مكة">مكة</option>
          <option value="المدينة المنورة">المدينة المنورة</option>
          <option value="الدمام">الدمام</option>
          <option value="الخبر">الخبر</option>
          <option value="تبوك">تبوك</option>
          <option value="بريدة">بريدة</option>
          <option value="الهفوف">الهفوف</option>
          <option value="خميس مشيط">خميس مشيط</option>
          <option value="خميس مشيط">خميس مشيط</option>
          <option value="الطائف">الطائف</option>
          <option value="جيزان">جيزان</option>
          <option value="نجران">نجران</option>
          <option value="الجبيل">الجبيل</option>
          <option value="ينبع">ينبع</option>
          <option value="أبها">أبها</option>
          <option value="عرعر">عرعر</option>
        </select>
                 
                  </label>

                  <label className="block mb-2">
                    السعر بالساعة : 
                    <input
                      type="text"
                      name="salary"
                      value={inputData.salary}
                      onChange={handleChange}
                      placeholder="السعر"
                      className="block w-full p-2 border rounded mt-1"
                    />
                  </label>

                  <label className="block mb-2">
                    رقم الجوال :
                    <input
                      type="text"
                      name="mobileNumber"
                      value={inputData.mobileNumber}
                      onChange={handleChange}
                      placeholder="رقم الجوال"
                      className="block w-full p-2 border rounded mt-1"
                    />
                  </label>

                  <label className="block mb-2">
                    الوصف :
                    <textarea
                      name="description"
                      value={inputData.description}
                      onChange={handleChange}
                      placeholder="الوصف"
                      className="block w-full p-2 border rounded mt-1"
                    />
                  </label>

                  <label className="block mb-2">
                    الصور :
                    <input
                      type="file"
                      name="photos"
                      multiple
                      onChange={handleFileChange}
                      className="block w-full p-2 border rounded mt-1"
                    />
                  </label>

                  <div className="flex justify-end mt-4 space-x-4">
                    <button
                      type="submit"
                      className="p-2 bg-cyan-700 text-white rounded hover:bg-cyan-900 ml-4"
                    >
                      إنشاء
                    </button>
                    <button
                      onClick={handleCloseModal}
                      className="p-2 bg-gray-400 text-white rounded hover:bg-gray-700"
                    >
                      إغلاق
                    </button>
                  </div>
                </form>
              </motion.div>
            </motion.div>
          </div>
        </div>
      )}
    </div>
  );
}

export default InputForm;
