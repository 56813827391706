import React from 'react'
import './App.css'
import pin from './pin.gif'

export default function Intro() {
  return (
    <div className="p-4 space-y-4 mb-10">
    <h1 className="text-3xl font-semibold mb-4 text-cyan-700 title">منصة مرغوب</h1>
    <div className="flex justify-center items-center col-span-1">
        {/* Placeholder for Icon */}
        <img src={pin} alt="Icon" className="w-20 h-20" />
      </div>
      
        <h3 className="font-bold m-10 text-lg text-cyan-950">أسهل طريقة تجمع مقدم الخدمة بالعميل من نفس المدينة</h3>
        
      
      
    
  </div>
  
  
  )
}
