import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import './App.css';

const SignIn = () => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const response = await axios.post('https://api.marghob.net/login', { usernameOrEmail, password });
      const { token } = response.data;
      login(token, usernameOrEmail); // Update authentication state
      setLoading(false);
      setUsernameOrEmail('');
      setPassword('');
      window.location.href = '/'; // Redirect to home page
    } catch (error) {
      console.error('Error details:', error);
      if (error.response) {
        if (error.response.status === 400) {
          setError('اسم المستخدم/البريد الإلكتروني أو كلمة المرور غير صحيحة');
        } else {
          setError(`Error: ${error.response.data.message || 'Something went wrong'}`);
        }
      } else if (error.request) {
        console.error('Error request:', error.request);
        setError('No response from server. Please try again later.');
      } else {
        console.error('Error message:', error.message);
        setError('Error: ' + error.message);
      }
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-intro">
      <div className="w-full max-w-md p-8 bg-sky-100 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center">تسجيل الدخول</h2>
        <form onSubmit={handleSubmit} className="mt-4">
          <div className="mb-4">
            <label className="block text-gray-700">اسم المستخدم أو البريد الإلكتروني</label>
            <input
              type="text"
              value={usernameOrEmail}
              onChange={(e) => setUsernameOrEmail(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded-lg"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">كلمة المرور</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full px-3 py-2 border rounded-lg"
            />
          </div>
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <button type="submit" className="w-full py-2 mt-4 text-white bg-cyan-700 rounded-lg hover:bg-cyan-600" disabled={loading}>
            {loading ? 'جاري تسجيل الدخول...' : 'تسجيل الدخول'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
