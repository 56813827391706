import React from 'react';

import BrowseLogo from './BrowseLogo.png'; // Replace with the actual path to your logo
import ProfilesLogo from './profilesLogo.png'; // Ensure this path is correct
import ChatLogo from './chatLogo.png'; // Ensure this path is correct
import GoalLogo from './goalLogo.png'; // Ensure this path is correct

const HowItWork = () => {
  return (
    <div className='container mx-auto px-4 py-10'>
      <section className='text-center mb-10'>
        <h2 className="text-3xl font-semibold text-cyan-700 mb-4">
          كيف تعمل منصة مرغوب
        </h2>
      </section>

      <section className='text-center mb-10'>
        <h2 className="text-2xl font-semibold text-cyan-700 mb-4">
          أستخدام منصة مرغوب بسيط وسهل
        </h2>
      </section>

      <div className='relative flex justify-center items-center flex-wrap gap-6'>
        <div className='flex flex-col items-center'>
          <div className='w-24 h-24 bg-white shadow-md rounded-full p-4 flex flex-col justify-center items-center text-center'>
            <img src={BrowseLogo} alt="Search" className='w-8 h-8 mb-2' />
            <h3 className='font-bold text-xl text-cyan-700'>
              ابحث
            </h3>
          </div>
          <p className='mt-2 text-s text-gray-800 leading-tight text-center w-40'>
            استخدم محرك البحث للعثور على مقدمي الخدمات في مدينتك
          </p>
        </div>

        <div className='flex flex-col items-center'>
          <div className='w-24 h-24 bg-white shadow-md rounded-full p-4 flex flex-col justify-center items-center text-center'>
            <img src={ProfilesLogo} alt="Browse" className='w-8 h-8 mb-2' />
            <h3 className='font-bold text-xl text-cyan-700'>
              تصفح
            </h3>
          </div>
          <p className='mt-2 text-s text-gray-800 leading-tight text-center w-40'>
            تصفح الملفات الشخصية، واستعرض أعمالهم السابقة والتقييمات.
          </p>
        </div>

        <div className='flex flex-col items-center'>
          <div className='w-24 h-24 bg-white shadow-md rounded-full p-4 flex flex-col justify-center items-center text-center'>
            <img src={ChatLogo} alt="Communicate" className='w-8 h-8 mb-2' />
            <h3 className='font-bold text-xl text-cyan-700'>
              تواصل
            </h3>
          </div>
          <p className='mt-2 text-s text-gray-800 leading-tight text-center w-40'>
            اتصل مباشرة بمقدم الخدمة لطرح الأسئلة أو تحديد موعد.
          </p>
        </div>

        <div className='flex flex-col items-center'>
          <div className='w-24 h-24 bg-white shadow-md rounded-full p-4 flex flex-col justify-center items-center text-center'>
            <img src={GoalLogo} alt="Book" className='w-8 h-8 mb-2' />
            <h3 className='font-bold text-xl text-cyan-700'>
              احجز
            </h3>
          </div>
          <p className='mt-2 text-s text-gray-800 leading-tight text-center w-40'>
            قم بحجز الخدمة التي تحتاجها بمرونة وسهولة.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWork;
