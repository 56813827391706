const JobCard = ({ jobTitle, onCardClick }) => (
  <div
    className="p-4 border  rounded-lg shadow-lg hover:bg-gray-100 hover:text-black cursor-pointer transition-transform transform hover:scale-105 bg-cyan-700  text-white "
    onClick={() => onCardClick(jobTitle)}
  >
    <h3 className="text-lg font-bold text-center">{jobTitle}</h3>
  </div>
);

export default JobCard;
