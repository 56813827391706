import React from 'react';
import PostItem from './PostItem';
import { useAuth } from './AuthContext';
import './App.css';

const PostList = () => {
  const { userPosts, refreshPosts } = useAuth();

  const onPostUpdated = () => {
    refreshPosts(); // Refresh the list of posts after an update
  };

  return (
    <div className='p-6  flex flex-col items-center '>
      <div className='max-w-6xl w-full space-y-12'>
      <h2 className="text-3xl font-semibold  text-cyan-700">منشوراتك</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 ">
        {userPosts.map(post => (
          <PostItem key={post._id} post={post} onPostUpdated={onPostUpdated} />
        ))}
      </div>
      </div>
    </div>
  );
};

export default PostList;
