import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const FreeLancers = ({ FreeLancer }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (selectedInput) => {
    setSelectedItem(selectedInput);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
  {FreeLancer.slice().reverse().map((input, index) => (
    <div
      key={input._id}
      className="border bg-cyan-700 p-6 rounded-md shadow-md cursor-pointer hover:shadow-lg transition-shadow duration-200"
      onClick={() => handleItemClick(input)}
    >
      <p className="text-white font-semibold">{input.name}</p>
      <p className="text-white">العمل: {input.job}</p>
      <p className="text-white">المدينة: {input.city}</p>
      <p className="text-white">السعر بالساعه : {input.salary} ريال </p>
    </div>
  ))}
</div>

      <AnimatePresence>
  {selectedItem && (
    <>
      <motion.div
        className="fixed inset-0 bg-gray-800 bg-opacity-50 z-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={handleCloseModal}
      ></motion.div>

      <motion.div
        className="fixed inset-0 z-20 flex items-center justify-center p-4 sm:p-6 overflow-y-auto"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.3 }}
      >
        <div className="p-4 sm:p-6 w-full max-w-lg max-h-full overflow-y-auto rounded shadow-md bg-sky-100">
          <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-cyan-700">بيانات المحترف</h2>
          <p className="block w-full p-2 border rounded mb-2 "><strong >الإسم:</strong> {selectedItem.name}</p>
          <p className="block w-full p-2 border rounded mb-2"><strong>العمل:</strong> {selectedItem.job}</p>
          <p className="block w-full p-2 border rounded mb-2"><strong>المدينة:</strong> {selectedItem.city}</p>
          <p className="block w-full p-2 border rounded mb-2"><strong>السعر بالساعة :</strong> {selectedItem.salary} ريال</p>
          <p className="block w-full p-2 border rounded mb-2"><strong>رقم الجوال:</strong> {selectedItem.mobileNumber}</p>
          <p className="block w-full p-2 border rounded mb-2"><strong>الوصف:</strong> {selectedItem.description}</p>

          {selectedItem.photos.length > 0 && (
            <div className="mt-4">
              <h3 className="text-lg sm:text-xl font-semibold text-cyan-700">الصور:</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
                {selectedItem.photos.map((photoUrl, index) => (
                  <img
                    key={index}
                    src={photoUrl}
                    alt={`Photos ${index + 1}`}
                    className="w-60 h-60 rounded"
                    onError={(e) => { e.target.onerror = null; e.target.src = 'https://api.marghob.net/default-image/default.png'; }} // Default image on error
                  />
                ))}
              </div>
            </div>
          )}

          <div className="flex justify-end mt-4">
            <button
              className="p-2 bg-cyan-700 text-white rounded hover:bg-cyan-900 transition-colors duration-200"
              onClick={handleCloseModal}
            >
              إغلاق
            </button>
          </div>
        </div>
      </motion.div>
    </>
  )}
</AnimatePresence>


    </div>
  );
};

export default FreeLancers;
