import React from 'react';
import './App.css';

import twitterIcon from './twitterIcon.png';
import email from './email-icon.png'; 
import LinkedInIcon from './LinkedInIcon.png'; // Adjust the path as needed

const Footer = () => {
  return (
    <footer className="bg-gray-400 p-4">
      <div className="container mx-auto text-center">

      <div className="flex justify-center mt-5 mb-5 space-x-4">
         
         <a href="https://twitter.com/Marghobapp" target="_blank" rel="noopener noreferrer" className="text-white" aria-label="Twitter">
           <img src={twitterIcon} alt="Twitter" className="w-10 h-10" />
         </a>
        
         <a href="https://www.linkedin.com/company/marghobapp/" target="_blank" rel="noopener noreferrer" className="text-white" aria-label="LinkedIn">
           <img src={LinkedInIcon} alt="LinkedIn" className="w-10 h-10" />
         </a>

         <a href="mailto:marghobapp@gmail.com" className="text-white" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
           <img src={email} alt="Email" className="w-10 h-10" />
         </a>
       </div>

        <p className="text-white text-sm md:text-base lg:text-lg title mb-5">
          منصة مرغوب &copy; 2024
        </p>
       
      </div>
    </footer>
  );
};

export default Footer;
