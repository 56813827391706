import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Navbar from './Navbar';
import Footer from './Footer';
import SignIn from './SignIn';
import SignUp from './SignUp';
import Home from './Home';
import { AuthProvider } from './AuthContext';
import SEO from './SEO';
import { Element } from 'react-scroll';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    if (token && storedUsername) {
      setIsAuthenticated(true);
      setUsername(storedUsername);
    }
  }, []);

  return (
    <HelmetProvider>
      <AuthProvider>
        <Router>
          <div className="App bg-intro flex flex-col min-h-screen">
            <Navbar isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} username={username} />
            <div className="flex-grow min-h-0">
              <Routes>
                <Route 
                  path="/signin" 
                  element={
                    <>
                      <SEO 
                        title="تسجيل الدخول - منصة مرغوب"
                        description="قم بتسجيل الدخول إلى منصة مرغوب للوصول إلى خدمات متنوعة في مدينتك."
                        keywords="تسجيل الدخول, منصة مرغوب, خدمات"
                        url="http://www.marghob.net/signin"
                      />
                      <SignIn setIsAuthenticated={setIsAuthenticated} setUsername={setUsername} />
                    </>
                  } 
                />
                <Route 
                  path="/signup" 
                  element={
                    <>
                      <SEO 
                        title="إنشاء حساب - منصة مرغوب"
                        description="قم بإنشاء حساب جديد على منصة مرغوب للبدء في استخدام الخدمات."
                        keywords="إنشاء حساب, منصة مرغوب, خدمات"
                        url="http://www.marghob.net/signup"
                      />
                      <SignUp setIsAuthenticated={setIsAuthenticated} setUsername={setUsername} />
                    </>
                  } 
                />
                <Route 
                  path="/" 
                  element={
                    <>
                      <SEO 
                        title="منصة مرغوب - أسهل طريقة تجمع مقدم الخدمة بالعميل من نفس المدينة"
                        description="منصة مرغوب - أسهل طريقة تجمع مقدم الخدمة بالعميل من نفس المدينة. اكتشف خدمات المصورين، كهربائي ، سباك ،ميكانيكي سيارات ، وغيرهم في مدينتك."
                        keywords="منصة مرغوب, خدمات, مصورين, مصور زواجات, مصور فوتوغرافي, مصور منتجات, كهربائي, سباك, ميكانيكي سيارات, منسق حفلات, مقدم خدمة, العميل, مدينتك, المملكة العربية السعودية, الرياض, جدة, مكة, المدينة المنورة, الدمام, الخبر, الطائف, بريدة, أبها, تبوك, حائل, الجبيل, نجران, القطيف, خميس مشيط, ينبع, كهربائي الرياض, سباك الرياض, ميكانيكي سيارات الرياض, مصور الرياض, منسق حفلات الرياض, كهربائي جدة, سباك جدة, ميكانيكي سيارات جدة, مصور جدة, منسق حفلات جدة"
                        url="http://www.marghob.net"
                        structuredData={{
                          "@context": "https://schema.org",
                          "@type": "Organization",
                          "name": "منصة مرغوب",
                          "url": "http://www.marghob.net",
                          "logo": "http://www.marghob.net/static/media/Logo.svg",
                          "sameAs": [
                            "https://www.linkedin.com/company/marghobapp/",
                            "https://twitter.com/Marghobapp?t=OJv4AzrZcgQlrq4opCh2qw&s=09",
                            "mailto:marghobapp@gmail.com"
                          ]
                        }}
                      />
                      <Home />
                    </>
                  } 
                />
              </Routes>
            </div>
            <Element name="contact">
              <Footer />
            </Element>
          </div>
        </Router>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;
